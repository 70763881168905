// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Waypoint } from 'react-waypoint';
import moment from 'moment';
import {
  Container, Row, Col, Button, Table, OverlayTrigger, Tooltip, Form,
} from 'react-bootstrap';
import { getUserType, countTeamAdminInTeams, toPascal } from 'components/Common/Utils';
import userIcon from 'images/user.svg';
import Downoad from 'images/download.svg';
import filterDown from 'images/down-arrow-grey.svg';
import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import ConfirmModal from 'components/Common/ConfirmModal';
import SwitchTeam from 'components/Layout/Header/SwitchTeam';
import TeamList from './components/TeamList';
import EditUser from './components/EditUser';


class UsersList extends React.Component {
  container = React.createRef();

  constructor(props) {
    super(props);
    const {
      usersCurrent,
    } = this.props;
    this.state = {
      Showfilter: false,
      csvHeaders: [
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Account Type', key: 'type' },
        { label: 'CreateAt', key: 'create_at' },
        { label: 'UpdateAt', key: 'update_at' },
      ],
      csvData: [],
      filters: usersCurrent.list.filters,
      showEditUserForm: false,
      isEditForm: 0,
      editUserData: {},
      successAlert: '',
      errorAlert: '',
      showConfirmModal: false,
      confirmModalData: null,
      confirmModalMessage: '',
      handleConfirm: () => {},
      showSwitchTeam: false,
      switchTeamModalSet: false,
    };
    this.csvRef = React.createRef();
    // console.log('constructor', usersCurrent.list); // eslint-disable-line no-console
  }

  componentDidUpdate(prevProps) {
    const {
      usersCurrent,
    } = this.props;
    if (prevProps.usersCurrent.forceLogout.status === 1 && usersCurrent.forceLogout.status === 2) {
      this.setAlerts('', usersCurrent.forceLogout.error);
    }
    if (prevProps.usersCurrent.forceLogout.status === 1 && usersCurrent.forceLogout.status === 3) {
      this.setAlerts('All sessions of User revoked successfully', '');
    }
    if (prevProps.usersCurrent.sendPasswordreset.status === 1 && usersCurrent.sendPasswordreset.status === 2) {
      this.setAlerts('', usersCurrent.sendPasswordreset.error);
    }
    if (prevProps.usersCurrent.sendPasswordreset.status === 1 && usersCurrent.sendPasswordreset.status === 3) {
      this.setAlerts('Password Reset Email sent successfully', '');
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    if (props.location && props.location.state && props.location.state.showSwitchTeamModal && !state.switchTeamModalSet) {
      const teamsArr = Object.keys(props.teams).map((teamId) => props.teams[teamId]);
      if (countTeamAdminInTeams(teamsArr).length > 1) {
        props.history.push({
          state: { showSwitchTeamModal: false },
        });
        props.setTeamModal(true);
        const newState = {
          showSwitchTeam: true,
          switchTeamModalSet: true,
        };
        return newState;
      }
      return null;
    }
    return null;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }

  onApplyFilter = () => {
    // this.setState({
    //   Showfilter: !this.state.Showfilter,
    // });
    this.setState((prevState) => ({ Showfilter: !prevState.Showfilter }));
  }

  resetFilter = () => {
    const { filters } = { ...this.state };
    if (filters && filters.primary_owner) {
      filters.primary_owner = 0;
    }
    if (filters && filters.workspace_admin) {
      filters.workspace_admin = 0;
    }
    if (filters && filters.member) {
      filters.member = 0;
    }
    if (filters && filters.deactivated_accounts) {
      filters.deactivated_accounts = 0;
    }
    this.setState({ filters }, () => this.getUsersList(null));
    this.onApplyFilter();
  }

  handleFilterChange = (name) => {
    const { filters } = { ...this.state };
    if (filters[name]) {
      filters[name] = !filters[name];
    } else {
      filters[name] = 1;
    }
    this.setState({ filters }, () => this.getUsersList(null));
  }

  componentDidMount = () => {
    this.getUsersList(null);
    this.typingTimer = 0;
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside =(event) => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        Showfilter: false,
      });
    }
  };

  getUsersList = (lastKey) => {
    const { filters } = this.state;
    const {
      getUsers,
      currentTeam,
    } = this.props;
    getUsers(currentTeam.currentTeamId, filters, lastKey); // no search no last_key for now
  }

  handleSearchInput = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        search: value,
      },
    }));
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    clearTimeout(this.typingTimer);
    this.typingTimer = 0;
    this.searchUsers();
  }

  searchUsers = () => {
    this.getUsersList(null);
  }

  resetSearchForm = () => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        search: '',
      },
    }), () => this.getUsersList(null));
    clearTimeout(this.typingTimer);
    this.typingTimer = 0; // since user cleared search field make new api call and set timer to 0, to avoid double request
  }

  handleSearchKeyUp = (e) => {
    const { filters } = this.state;
    if (filters.search.length > 0 && e.keyCode !== 13) { // check if search key entered and last key by user is not enter key then only make a search
      clearTimeout(this.typingTimer);
      this.typingTimer = 0;
      this.typingTimer = setTimeout(() => {
        this.searchUsers();
      }, 1000); // search after 1 seconds
    }
  }

  handleKeyDown = () => {
    // do nothing, just to avoid lint error of event key handle requires
  }

  handleWaypointEnter = () => {
    const { usersCurrent } = this.props;
    this.getUsersList(usersCurrent.list.lastKey);
  }

  getUsersCsvData = async () => {
    const {
      filters,
    } = this.state;
    const {
      getUsersCsv,
      currentTeam,
    } = this.props;
    const response = await getUsersCsv(currentTeam.currentTeamId, filters);
    const responseWithUserType = response.data.map((user) => {
      const userType = getUserType(user);
      const appsModValues = {};
      Object.keys(user.apps).forEach((appName) => {
        if (user.apps[appName]) {
          appsModValues[appName] = 1;
        } else {
          appsModValues[appName] = 0;
        }
      });
      return {
        ...user,
        type: userType.display_name,
        create_at: moment(user.create_at).format('MM/DD/YYYY'),
        update_at: moment(user.update_at).format('MM/DD/YYYY'),
        apps: appsModValues,
      };
    });
    let dynamicCsvHeaders = [];
    if (responseWithUserType.length > 0 && responseWithUserType[0].apps) {
      dynamicCsvHeaders = Object.keys(responseWithUserType[0].apps)
        .map((appName) => ({ label: toPascal(appName), key: `apps.${appName}` }));
    }
    const fixCsvHeaders = [
      { label: 'First Name', key: 'first_name' },
      { label: 'Last Name', key: 'last_name' },
      { label: 'Email', key: 'email' },
      { label: 'Account Type', key: 'type' },
      { label: 'CreateAt', key: 'create_at' },
      { label: 'UpdateAt', key: 'update_at' },
    ];
    this.setState({
      csvHeaders: [...fixCsvHeaders, ...dynamicCsvHeaders],
      csvData: responseWithUserType,
    }, () => {
      this.forceUpdate(); // render method was not calling automatically after setState so I had to forceUpdate
      this.csvRef.current.link.click(); // csvlink async method dont work so I have used two buttons and onclick of first button call api , then call csvlink click
    });
  }

  handleUserEditAction = (evt, userId) => {
    const { usersCompleteList } = this.props;
    evt.preventDefault();
    // console.log('user: ', usersCompleteList[userId]); // eslint-disable-line no-console
    this.setState(
      {
        showEditUserForm: true,
        isEditForm: 1,
        editUserData: usersCompleteList[userId],
      },
    );
  }

  handleUserLogoutAction = (userId) => {
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      forceLogout,
      currentTeam,
    } = this.props;
    forceLogout(currentTeam.currentTeamId, userId);
    this.cancelConfirmModal();
  }

  handleUserSendResetPasswordAction = (userId) => {
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      sendPasswordreset,
      usersCompleteList,
    } = this.props;
    const user = usersCompleteList[userId];
    sendPasswordreset({ email: user.email });
    this.cancelConfirmModal();
  }

  handleInvite = (e) => {
    e.preventDefault();
    this.setState(
      {
        showEditUserForm: true,
        isEditForm: 0,
      },
    );
  }

  handleCloseEditUserForm = () => {
    this.setState({ showEditUserForm: false });
  }

  handleEditUserFormSubmit = (userData) => {
    const { postUser, currentTeam } = this.props;
    postUser(
      currentTeam.currentTeamId,
      userData,
    );
    this.setState({ showEditUserForm: false });
  }

  showConfirmModal = (evt, recordId, method) => {
    evt.preventDefault();
    if (method === 'force_logout') {
      this.setState({
        showConfirmModal: true,
        confirmModalData: recordId,
        confirmModalMessage: 'Are you sure You want to revoke all sessions of this User?',
        handleConfirm: this.handleUserLogoutAction,
      });
    } else {
      this.setState({
        showConfirmModal: true,
        confirmModalData: recordId,
        confirmModalMessage: 'Are you sure You want to send Password reset email to this User?',
        handleConfirm: this.handleUserSendResetPasswordAction,
      });
    }
  }

  cancelConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
      confirmModalData: null,
      confirmModalMessage: '',
      handleConfirm: () => {},
    });
  }

  handleSwitchTeam = (e) => {
    e.preventDefault();
    this.setState({ showSwitchTeam: true });
  }

  handleSwitchTeamCancel = () => {
    this.setState({ showSwitchTeam: false });
  }

  handleTeamChosen = (teamId) => {
    const {
      currentTeam,
      setCurrentTeamId,
    } = this.props;
    if (teamId !== currentTeam.currentTeamId) {
      setCurrentTeamId(teamId);
      setTimeout(() => {
        window.location.reload(); // after delay of 100 ms which is required to change team, reload the page after setting selected team as current team
      }, 100);
    }
  }

  render() {
    const {
      Showfilter,
      filters,
      csvHeaders,
      csvData,
      showEditUserForm,
      editUserData,
      isEditForm,
      successAlert,
      errorAlert,
      showConfirmModal,
      confirmModalData,
      confirmModalMessage,
      handleConfirm,
      showSwitchTeam,
    } = this.state;
    const {
      usersCurrent,
      usersCompleteList,
      currentTeam,
      teams,
    } = this.props;
    let usersWaypoint = null;
    if (usersCurrent.list.lastKey) {
      usersWaypoint = (
        <div>
          <Waypoint
            onEnter={() => { this.handleWaypointEnter(this); }}
            threshold={4.0}
          />
        </div>
      );
    }
    // console.log('completeUserList: ', usersCompleteList);
    return (
      <Layout>
        <DashBoard>
          <div className="manage-teammates" id="manage-users">
            <div className="title-section">
              <Container fluid>
                <Row>
                  <Col md={{ span: 7, offset: 5 }}>
                    <ul className="list-inline">
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={(
                            <Tooltip id="download">
                              Download users list
                            </Tooltip>
                          )}
                        >
                          <Button
                            variant="default download-button"
                            onClick={() => { this.getUsersCsvData(); }}
                          >
                            <img src={Downoad} alt="boomea" />
                          </Button>
                        </OverlayTrigger>
                      </li>
                      <li style={{ display: 'none' }}>
                        <CSVLink
                          data={csvData}
                          headers={csvHeaders}
                          filename="boomea-users.csv"
                          ref={this.csvRef}
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
              <h2>
                <img src={userIcon} alt="boomea" />
                {' '}
                MANAGE USERS
              </h2>
            </div>
            <div className="manage-users-table">
              <div className="manage-area">
                <ErrorAlert
                  error={errorAlert}
                  onAlertDismissed={this.onAlertDismissed}
                  alertType="errorAlert"
                />
                <SuccessAlert
                  success={successAlert}
                  onAlertDismissed={this.onAlertDismissed}
                  alertType="successAlert"
                />
                <Container fluid>
                  <Row>
                    <Col md={12}>
                      <div className="search-area-form">
                        <div className="serach-input">
                          <Form onSubmit={this.handleSearchSubmit}>
                            <input
                              type="text"
                              className="form-control"
                              id="search-comp-dir"
                              placeholder="search"
                              value={(filters && filters.search) ? filters.search : ''}
                              onChange={this.handleSearchInput}
                              onKeyUp={this.handleSearchKeyUp}
                            />
                            {filters && filters.search
                              ? (
                                <i
                                  onClick={this.resetSearchForm}
                                  id="searchclear"
                                  role="link"
                                  onKeyDown={this.handleKeyDown}
                                  tabIndex={0}
                                  className="clearable__clear"
                                >
                                  ×
                                </i>
                              )
                              : (null)}
                          </Form>
                        </div>
                        <div className="filters-button" ref={this.container}>
                          <Button variant={Showfilter ? 'filter active' : 'filter'} onClick={this.onApplyFilter}>
                            {' '}
                            Filters
                            <img src={filterDown} alt="boomea" />
                          </Button>
                          {
                            Showfilter && (
                            <form className="filters-dropdown-menu">
                              <div className="filter-header">
                                <Row>
                                  <Col xs={6}>Filters</Col>
                                  <Col xs={6} className="text-right">
                                    <button onClick={this.resetFilter} type="button">Reset Filtes</button>
                                  </Col>
                                </Row>
                              </div>
                              <div className="filter-body">
                                <Row>
                                  <Col xs={6}>
                                    <h6>Account type:</h6>
                                    <label className="checkbox-container">
                                      <input
                                        type="checkbox"
                                        onChange={() => this.handleFilterChange('primary_owner')}
                                        name="primary_owner"
                                        defaultChecked={(filters && filters.primary_owner) ? 'checked' : ''}
                                      />
                                      <span className="checkmark" />
                                      {' '}
                                      Primary Owner
                                    </label>
                                    <label className="checkbox-container">
                                      <input
                                        type="checkbox"
                                        onChange={() => this.handleFilterChange('workspace_admin')}
                                        name="workspace_admin"
                                        defaultChecked={(filters && filters.workspace_admin) ? 'checked' : ''}
                                      />
                                      <span className="checkmark" />
                                      {' '}
                                      Workspace Admin
                                    </label>
                                    <label className="checkbox-container">
                                      <input
                                        type="checkbox"
                                        onChange={() => this.handleFilterChange('member')}
                                        name="member"
                                        defaultChecked={(filters && filters.member) ? 'checked' : ''}
                                      />
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                      {' '}
                                      Member
                                    </label>
                                  </Col>
                                </Row>
                              </div>
                              <div className="filter-footer">
                                <Row>
                                  <Col xs={6}>
                                    <label className="checkbox-container">
                                      <input
                                        type="checkbox"
                                        onChange={() => this.handleFilterChange('deactivated_accounts')}
                                        name="deactivated_accounts"
                                        defaultChecked={(filters && filters.deactivated_accounts) ? 'checked' : ''}
                                      />
                                      <span className="checkmark" />
                                      {' '}
                                      Show deactivated accounts
                                    </label>
                                  </Col>
                                  <Col xs={6} className="text-right">
                                    <button type="submit" onClick={this.onApplyFilter}>OK</button>
                                  </Col>
                                </Row>
                              </div>
                            </form>
                            )
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="scroll-area">
                        <div className="table-area">
                          <Table>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Account Type</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {usersCurrent.list.records.map((id) => (
                                <TeamList
                                  key={id}
                                  user={usersCompleteList[id]}
                                  showConfirmModal={this.showConfirmModal}
                                />
                              ))}
                              {usersCurrent.list.state === 2 ? 'Loading...' : null}
                            </tbody>
                          </Table>
                        </div>
                        {usersWaypoint}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <EditUser
              show={showEditUserForm}
              handleCloseEditUserForm={this.handleCloseEditUserForm}
              isEditForm={isEditForm}
              handleSubmit={this.handleEditUserFormSubmit}
              user={editUserData}
            />
          </div>
        </DashBoard>
        <ConfirmModal
          show={showConfirmModal}
          data={confirmModalData}
          confirmModalMessage={confirmModalMessage}
          handleCancel={this.cancelConfirmModal}
          handleConfirm={handleConfirm}
        />
        <SwitchTeam
          show={showSwitchTeam}
          onCancel={this.handleSwitchTeamCancel}
          teams={teams}
          handleTeamChosen={this.handleTeamChosen}
          currentTeam={currentTeam}
        />
      </Layout>
    );
  }
}

UsersList.propTypes = {
  postUser: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUsersCsv: PropTypes.func.isRequired,
  forceLogout: PropTypes.func.isRequired,
  sendPasswordreset: PropTypes.func.isRequired,
  setCurrentTeamId: PropTypes.func.isRequired,
  setTeamModal: PropTypes.func.isRequired,
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  usersCompleteList: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  usersCurrent: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.objectOf(
            PropTypes.oneOfType([
              PropTypes.number,
              PropTypes.string,
            ]),
          ),
        ]),
      ),
    ]),
  ),
  teams: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

UsersList.defaultProps = {
  currentTeam: '',
  usersCompleteList: {},
  usersCurrent: {},
};

export default UsersList;
