// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';

import Select from 'react-select';

import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import Settings from 'images/settings.svg';

const fileretentionOptions = [
  {
    label: 'Keep files forever',
    value: 0,
  },
  {
    label: 'Keep files, but only for a set duration',
    value: 1,
  },
];
const fileretentiontDuration = [
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Months',
    value: 'm',
  },
  {
    label: 'Year',
    value: 'y',
  },
];

class FileRetention extends React.PureComponent {
  constructor(props) {
    super(props);
    const { fileRetentionData } = this.props;
    // console.log('constructor', fileRetentionData); // eslint-disable-line no-console
    let fileRetenionPeriodObj = null;
    let fileRetenionValueObj = null;
    let fileRetentionCount = 0;
    if (fileRetentionData.value !== undefined) {
      fileRetenionValueObj = fileretentionOptions.find((option) => option.value === fileRetentionData.value);
    }
    if (fileRetentionData.duration) {
      fileRetenionPeriodObj = fileretentiontDuration.find((option) => option.value === fileRetentionData.duration.period);
      fileRetentionCount = fileRetentionData.duration.count;
    }
    this.state = {
      successAlert: '',
      errorAlert: '',
      fileRetentionData,
      fileRetention: {
        value: fileRetenionValueObj,
        duration: {
          count: fileRetentionCount,
          period: fileRetenionPeriodObj,
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    if (!equal(props.fileRetentionData, state.fileRetentionData)) {
      const { fileRetentionData } = props;
      let fileRetenionPeriodObj = null;
      let fileRetenionValueObj = null;
      let fileRetentionCount = 0;
      if (fileRetentionData.value !== undefined) {
        fileRetenionValueObj = fileretentionOptions.find((option) => option.value === fileRetentionData.value);
      }
      if (fileRetentionData.duration) {
        fileRetenionPeriodObj = fileretentiontDuration.find((option) => option.value === fileRetentionData.duration.period);
        fileRetentionCount = fileRetentionData.duration.count;
      }
      const newState = {
        fileRetentionData,
        fileRetention: {
          value: fileRetenionValueObj,
          duration: {
            count: fileRetentionCount,
            period: fileRetenionPeriodObj,
          },
        },
      };
      // console.log('newState: ', newState);
      return newState;
    }
    return null;
  }

  componentDidMount = () => {
    const { fetchFileRetention, currentTeam } = this.props;
    fetchFileRetention(currentTeam.currentTeamId); // get initial file retention values
  }

  componentDidUpdate(prevProps) {
    const { uiFileRetention } = this.props;
    if (prevProps.uiFileRetention.post.status === 1 && uiFileRetention.post.status === 2) {
      this.setAlerts('', uiFileRetention.post.error);
    }
    if (prevProps.uiFileRetention.post.status === 1 && uiFileRetention.post.status === 3) {
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }

  changeSelectedValue = (value) => {
    this.setState(
      (prevState) => ({
        fileRetention: {
          ...prevState.fileRetention,
          value,
        },
      }),
    );
  }

  changeDuration = (selectedDuration) => {
    this.setState(
      (prevState) => ({
        fileRetention: {
          ...prevState.fileRetention,
          duration: {
            ...prevState.fileRetention.duration,
            period: selectedDuration,
          },
        },
      }),
    );
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        fileRetention: {
          ...prevState.fileRetention,
          duration: {
            ...prevState.fileRetention.duration,
            [name]: value,
          },
        },
      }),
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const { postFileRetention, currentTeam } = this.props;
    const { fileRetention } = this.state;
    const fileRetentionSaveObj = {};
    fileRetentionSaveObj.value = fileRetention.value.value;
    fileRetentionSaveObj.duration = {
      count: parseInt(fileRetention.duration.count, 10),
      period: fileRetention.duration.period.value,
    };
    // console.log('fileRetentionSaveObj: ', fileRetentionSaveObj);
    postFileRetention(currentTeam.currentTeamId, fileRetentionSaveObj); // save file retention values
    // const { fileRetention } = this.state;
  }

  render() {
    const {
      fileRetention,
      successAlert,
      errorAlert,
    } = this.state;
    return (
      <Layout>
        <DashBoard>
          <div className="space-settings">
            <div className="title-section">
              <h2>
                <img src={Settings} alt="boomea" />
                TEAM SETTINGS
              </h2>
            </div>
            <Container fluid>
              <Row>
                <Col md={{ offset: 2, span: 8 }}>
                  <div className="manage-users-table">
                    <div className="space-header">
                      <Container fluid>
                        <Row>
                          <Col md={12}>FILE RETENTION</Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="manage-area retention">
                      <ErrorAlert
                        error={errorAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="errorAlert"
                      />
                      <SuccessAlert
                        success={successAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="successAlert"
                      />
                      <Container fluid>
                        <Form onSubmit={this.submitForm}>
                          <Row>
                            <Col md={12}>
                              <Form.Group controlId="retention-select">
                                <Select
                                  options={fileretentionOptions}
                                  value={(fileRetention && fileRetention.value)
                                    ? fileRetention.value
                                    : null}
                                  className="select-container"
                                  name="value"
                                  onChange={this.changeSelectedValue}
                                  required
                                />
                              </Form.Group>
                            </Col>
                            {(fileRetention && fileRetention.value && fileRetention.value.value)
                              ? (
                                <Col md={12}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>Duration:</Form.Label>
                                    <input
                                      type="number"
                                      value={(fileRetention && fileRetention.duration && fileRetention.duration.count)
                                        ? fileRetention.duration.count
                                        : ''}
                                      className="select-container days-count"
                                      onChange={this.onInputChange}
                                      name="count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={fileretentiontDuration}
                                      value={(fileRetention && fileRetention.duration && fileRetention.duration.period)
                                        ? fileRetention.duration.period
                                        : null}
                                      className="select-container days-type"
                                      onChange={this.changeDuration}
                                      name="period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null }
                          </Row>

                          <div className="line-sep" />
                          <Row>
                            <Col md={12}>
                              <div className="retention-message">
                                <Form.Group controlId="retention-duration">
                                  <Form.Label>Note:</Form.Label>
                                  By default, Boomea keeps all your files for the lifetime of your team.
                                  If you&apos;d like, you can have them deleted after a set amount of time.
                                  Note that this affects all files — including images, docs, files in Boomea posts and more
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={12}>
                              <Button type="submit" variant="blue">SAVE CHANGES</Button>
                            </Col>
                          </Row>
                        </Form>
                      </Container>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

FileRetention.propTypes = {
  fetchFileRetention: PropTypes.func.isRequired,
  postFileRetention: PropTypes.func.isRequired,
  fileRetentionData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  uiFileRetention: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

FileRetention.defaultProps = {
  fileRetentionData: {},
};

export default FileRetention;
