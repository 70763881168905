// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Media } from 'react-bootstrap';
import DotsMenu from 'images/dots.svg';
// export default class USerList extends Component {
//   render() {
//     const {
//       phone_number, sms_number, fname, sname, email,
//     } = this.props;
//
//     return (
//       <tr>
//         <td>
//           <Media>
//             <img
//               width={42}
//               height={42}
//               className="mr-3"
//               src={Avatar}
//               alt="username"
//             />
//             <Media.Body>
//               <h5>
//                 {`${fname} ${sname}`}
//                 {' '}
//               </h5>
//             </Media.Body>
//           </Media>
//         </td>
//         <td>{phone_number}</td>
//         <td>{sms_number}</td>
//         <td>{email}</td>
//         <td className="dots-menu">
//           <Dropdown drop="left">
//             <Dropdown.Toggle variant="primary" id="dropdown-basic">
//               <img src={DotsMenu} alt="boomea" width={16} />
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
//               <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </td>
//       </tr>
//     );
//   }
// }


const phoneNumberFormat = (number) => {
  if (number.length === 10) {
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, number.length)}`;
  }
  return number;
};

const CompanyDirectoryItem = ({
  id, phoneNumber, smsNumber, name, email, handleEdit, handleDelete, imgSrc,
}) => (
  <tr>
    <td>
      <Media>
        <img
          width={42}
          height={42}
          className="mr-3 rounded-circle"
          src={imgSrc}
          alt="user"
        />
        <Media.Body>
          <h5>
            {`${name}`}
            {' '}
          </h5>
        </Media.Body>
      </Media>
    </td>
    <td>{phoneNumberFormat(phoneNumber)}</td>
    <td>{smsNumber}</td>
    <td>{email}</td>
    <td className="dots-menu">
      <Dropdown drop="left">
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          <img src={DotsMenu} alt="boomea" width={16} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#/" onClick={() => { handleEdit(id); }}>Edit</Dropdown.Item>
          <Dropdown.Item href="#/" onClick={() => { handleDelete(id); }}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </td>
  </tr>
);

CompanyDirectoryItem.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  smsNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default CompanyDirectoryItem;
