// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import Select from 'react-select';

import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import Settings from 'images/settings.svg';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';

const voiceRetentionOptions = [
  {
    label: 'Keep files forever',
    value: 0,
  },
  {
    label: 'Keep files, but only for a set duration',
    value: 1,
  },
];
const voiceRetentiontDuration = [
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Months',
    value: 'm',
  },
  {
    label: 'Years',
    value: 'y',
  },
];

class VoiceRetention extends React.PureComponent {
  constructor(props) {
    super(props);
    const { voiceRetentionData } = this.props;
    // console.log('constructor', voiceRetentionData); // eslint-disable-line no-console
    let callHistoryPeriodObj = null;
    let callHistoryValueObj = null;
    let callRecordingPeriodObj = null;
    let callRecordingValueObj = null;
    let voicemailPeriodObj = null;
    let voicemailValueObj = null;
    let communicationHistoryPeriodObj = null;
    let communicationHistoryValueObj = null;
    let callHistoryCount = 0;
    let callRecordingCount = 0;
    let voicemailCount = 0;
    let communicationHistoryCount = 0;
    if (voiceRetentionData.call_history) {
      callHistoryValueObj = voiceRetentionOptions
        .find((option) => option.value === voiceRetentionData.call_history.value);
      callHistoryPeriodObj = voiceRetentiontDuration
        .find((option) => option.value === voiceRetentionData.call_history.duration.period);
      callHistoryCount = voiceRetentionData.call_history.duration.count;
    }
    if (voiceRetentionData.call_recordings) {
      callRecordingValueObj = voiceRetentionOptions
        .find((option) => option.value === voiceRetentionData.call_recordings.value);
      callRecordingPeriodObj = voiceRetentiontDuration
        .find((option) => option.value === voiceRetentionData.call_recordings.duration.period);
      callRecordingCount = voiceRetentionData.call_recordings.duration.count;
    }
    if (voiceRetentionData.voicemails) {
      voicemailValueObj = voiceRetentionOptions
        .find((option) => option.value === voiceRetentionData.voicemails.value);
      voicemailPeriodObj = voiceRetentiontDuration
        .find((option) => option.value === voiceRetentionData.voicemails.duration.period);
      voicemailCount = voiceRetentionData.voicemails.duration.count;
    }
    if (voiceRetentionData.communication_history) {
      communicationHistoryValueObj = voiceRetentionOptions
        .find((option) => option.value === voiceRetentionData.communication_history.value);
      communicationHistoryPeriodObj = voiceRetentiontDuration
        .find((option) => option.value === voiceRetentionData.communication_history.duration.period);
      communicationHistoryCount = voiceRetentionData.communication_history.duration.count;
    }
    this.state = {
      successAlert: '',
      errorAlert: '',
      voiceRetentionData,
      voiceRetention: {
        call_history: {
          value: callHistoryValueObj,
          duration: {
            count: callHistoryCount,
            period: callHistoryPeriodObj,
          },
        },
        call_recordings: {
          value: callRecordingValueObj,
          duration: {
            count: callRecordingCount,
            period: callRecordingPeriodObj,
          },
        },
        voicemails: {
          value: voicemailValueObj,
          duration: {
            count: voicemailCount,
            period: voicemailPeriodObj,
          },
        },
        communication_history: {
          value: communicationHistoryValueObj,
          duration: {
            count: communicationHistoryCount,
            period: communicationHistoryPeriodObj,
          },
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    const { voiceRetentionData } = props;
    // console.log('constructor2', voiceRetentionData); // eslint-disable-line no-console
    if (!equal(voiceRetentionData, state.voiceRetentionData)) {
      // console.log('constructor', fileRetentionData); // eslint-disable-line no-console
      let callHistoryPeriodObj = null;
      let callHistoryValueObj = null;
      let callRecordingPeriodObj = null;
      let callRecordingValueObj = null;
      let voicemailPeriodObj = null;
      let voicemailValueObj = null;
      let communicationHistoryPeriodObj = null;
      let communicationHistoryValueObj = null;
      let callHistoryCount = 0;
      let callRecordingCount = 0;
      let voicemailCount = 0;
      let communicationHistoryCount = 0;
      if (voiceRetentionData.call_history) {
        callHistoryValueObj = voiceRetentionOptions
          .find((option) => option.value === voiceRetentionData.call_history.value);
        callHistoryPeriodObj = voiceRetentiontDuration
          .find((option) => option.value === voiceRetentionData.call_history.duration.period);
        callHistoryCount = voiceRetentionData.call_history.duration.count;
      }
      if (voiceRetentionData.call_recordings) {
        callRecordingValueObj = voiceRetentionOptions
          .find((option) => option.value === voiceRetentionData.call_recordings.value);
        callRecordingPeriodObj = voiceRetentiontDuration
          .find((option) => option.value === voiceRetentionData.call_recordings.duration.period);
        callRecordingCount = voiceRetentionData.call_recordings.duration.count;
      }
      if (voiceRetentionData.voicemails) {
        voicemailValueObj = voiceRetentionOptions
          .find((option) => option.value === voiceRetentionData.voicemails.value);
        voicemailPeriodObj = voiceRetentiontDuration
          .find((option) => option.value === voiceRetentionData.voicemails.duration.period);
        voicemailCount = voiceRetentionData.voicemails.duration.count;
      }
      if (voiceRetentionData.communication_history) {
        communicationHistoryValueObj = voiceRetentionOptions
          .find((option) => option.value === voiceRetentionData.communication_history.value);
        communicationHistoryPeriodObj = voiceRetentiontDuration
          .find((option) => option.value === voiceRetentionData.communication_history.duration.period);
        communicationHistoryCount = voiceRetentionData.communication_history.duration.count;
      }
      const newState = {
        voiceRetentionData,
        voiceRetention: {
          call_history: {
            value: callHistoryValueObj,
            duration: {
              count: callHistoryCount,
              period: callHistoryPeriodObj,
            },
          },
          call_recordings: {
            value: callRecordingValueObj,
            duration: {
              count: callRecordingCount,
              period: callRecordingPeriodObj,
            },
          },
          voicemails: {
            value: voicemailValueObj,
            duration: {
              count: voicemailCount,
              period: voicemailPeriodObj,
            },
          },
          communication_history: {
            value: communicationHistoryValueObj,
            duration: {
              count: communicationHistoryCount,
              period: communicationHistoryPeriodObj,
            },
          },
        },
      };
      // console.log('newState: ', newState);
      return newState;
    }
    return null;
  }

  componentDidMount = () => {
    const {
      fetchVoiceRetention,
      currentTeam,
    } = this.props;
    fetchVoiceRetention(currentTeam.currentTeamId); // get initial message retention values
  }

  componentDidUpdate(prevProps) {
    const { uiVoiceRetention } = this.props;
    if (prevProps.uiVoiceRetention.post.status === 1 && uiVoiceRetention.post.status === 2) {
      this.setAlerts('', uiVoiceRetention.post.error);
    }
    if (prevProps.uiVoiceRetention.post.status === 1 && uiVoiceRetention.post.status === 3) {
      this.setAlerts('Settings Saved Successfully', '');
    }
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }


  changeValue = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const objName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        voiceRetention: {
          ...prevState.voiceRetention,
          [objName]: {
            ...prevState.voiceRetention[objName],
            [name]: selectedValue,
          },
        },
      }),
    );
  }

  changePeriod = (selectedValue, action) => {
    const splittedNameArr = action.name.split('-');
    const objName = splittedNameArr[0];
    const name = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        ...prevState,
        voiceRetention: {
          ...prevState.voiceRetention,
          [objName]: {
            ...prevState.voiceRetention[objName],
            duration: {
              ...prevState.voiceRetention[objName].duration,
              [name]: selectedValue,
            },
          },
        },
      }),
    );
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    const splittedNameArr = name.split('-');
    const objName = splittedNameArr[0];
    const inputName = splittedNameArr[1];
    this.setState(
      (prevState) => ({
        voiceRetention: {
          ...prevState.voiceRetention,
          [objName]: {
            ...prevState.voiceRetention[objName],
            duration: {
              ...prevState.voiceRetention[objName].duration,
              [inputName]: value,
            },
          },
        },
      }),
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      postVoiceRetention,
      currentTeam,
    } = this.props;
    const { voiceRetention } = this.state;
    const voiceRetentionSaveObj = {
      call_history: {},
      call_recordings: {},
      voicemails: {},
      communication_history: {},
    };
    voiceRetentionSaveObj.call_history.value = voiceRetention.call_history.value.value;
    voiceRetentionSaveObj.call_history.duration = {
      count: parseInt(voiceRetention.call_history.duration.count, 10),
      period: voiceRetention.call_history.duration.period.value,
    };
    voiceRetentionSaveObj.call_recordings.value = voiceRetention.call_recordings.value.value;
    voiceRetentionSaveObj.call_recordings.duration = {
      count: parseInt(voiceRetention.call_recordings.duration.count, 10),
      period: voiceRetention.call_recordings.duration.period.value,
    };
    voiceRetentionSaveObj.voicemails.value = voiceRetention.voicemails.value.value;
    voiceRetentionSaveObj.voicemails.duration = {
      count: parseInt(voiceRetention.voicemails.duration.count, 10),
      period: voiceRetention.voicemails.duration.period.value,
    };
    voiceRetentionSaveObj.communication_history.value = voiceRetention.communication_history.value.value;
    voiceRetentionSaveObj.communication_history.duration = {
      count: parseInt(voiceRetention.communication_history.duration.count, 10),
      period: voiceRetention.communication_history.duration.period.value,
    };
    // console.log('submit: ', voiceRetention); // eslint-disable-line no-console
    postVoiceRetention(voiceRetentionSaveObj, currentTeam.currentTeamId); // save file retention values
    // const { voiceRetention } = this.state;
  }

  render() {
    const {
      voiceRetention,
      successAlert,
      errorAlert,
    } = this.state;
    return (
      <Layout>
        <DashBoard>
          <div className="space-settings">
            <div className="title-section">
              <h2>
                <img src={Settings} alt="boomea" />
                TEAM SETTINGS
              </h2>
            </div>
            <Container fluid>
              <Row>
                <Col md={{ offset: 2, span: 8 }}>
                  <div className="manage-users-table">
                    <div className="space-header">
                      <Container fluid>
                        <Row>
                          <Col md={12}>VOICE RETENTION</Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="manage-area retention">
                      <ErrorAlert
                        error={errorAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="errorAlert"
                      />
                      <SuccessAlert
                        success={successAlert}
                        onAlertDismissed={this.onAlertDismissed}
                        alertType="successAlert"
                      />
                      <Form onSubmit={this.submitForm}>
                        <Container fluid>
                          <Row>
                            <Col md={7}>
                              <Form.Group controlId="retention-select">
                                <Form.Label>CALL HISTORY:</Form.Label>
                                <Select
                                  options={voiceRetentionOptions}
                                  value={
                                    (voiceRetention
                                      && voiceRetention.call_history
                                      && voiceRetention.call_history.value
                                    )
                                      ? voiceRetention.call_history.value
                                      : null
                                  }
                                  className="select-container"
                                  name="call_history-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(voiceRetention
                              && voiceRetention.call_history
                              && voiceRetention.call_history.value
                              && voiceRetention.call_history.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE FILES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.call_history
                                          && voiceRetention.call_history.duration
                                          && voiceRetention.call_history.duration.count
                                        )
                                          ? voiceRetention.call_history.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="call_history-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={voiceRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.call_history
                                          && voiceRetention.call_history.duration
                                          && voiceRetention.call_history.duration.period
                                        )
                                          ? voiceRetention.call_history.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="call_history-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              )
                              : null }

                            <Col md={7}>
                              <Form.Group controlId="retention-select">
                                <Form.Label>CALL RECORDINGS:</Form.Label>
                                <Select
                                  options={voiceRetentionOptions}
                                  value={
                                    (voiceRetention
                                      && voiceRetention.call_recordings
                                      && voiceRetention.call_recordings.value
                                    )
                                      ? voiceRetention.call_recordings.value
                                      : null
                                  }
                                  className="select-container"
                                  name="call_recordings-value"
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>
                            {(voiceRetention
                              && voiceRetention.call_recordings
                              && voiceRetention.call_recordings.value
                              && voiceRetention.call_recordings.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE FILES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.call_recordings
                                          && voiceRetention.call_recordings.duration
                                          && voiceRetention.call_recordings.duration.count
                                        )
                                          ? voiceRetention.call_recordings.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="call_recordings-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={voiceRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.call_recordings
                                          && voiceRetention.call_recordings.duration
                                          && voiceRetention.call_recordings.duration.period
                                        )
                                          ? voiceRetention.call_recordings.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="call_recordings-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null }

                            <Col md={7}>
                              <Form.Group controlId="retention-select">
                                <Form.Label>VOICEMAILS:</Form.Label>
                                <Select
                                  options={voiceRetentionOptions}
                                  className="select-container"
                                  name="voicemails-value"
                                  value={
                                    (voiceRetention
                                      && voiceRetention.voicemails
                                      && voiceRetention.voicemails.value
                                    )
                                      ? voiceRetention.voicemails.value
                                      : null
                                  }
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(voiceRetention
                              && voiceRetention.voicemails
                              && voiceRetention.voicemails.value
                              && voiceRetention.voicemails.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE FILES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.voicemails
                                          && voiceRetention.voicemails.duration
                                          && voiceRetention.voicemails.duration.count
                                        )
                                          ? voiceRetention.voicemails.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="voicemails-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={voiceRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.voicemails
                                          && voiceRetention.voicemails.duration
                                          && voiceRetention.voicemails.duration.period
                                        )
                                          ? voiceRetention.voicemails.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="voicemails-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null }

                            <Col md={7}>
                              <Form.Group controlId="retention-select">
                                <Form.Label>COMMUNICATION HISTORY:</Form.Label>
                                <Select
                                  options={voiceRetentionOptions}
                                  className="select-container"
                                  name="communication_history-value"
                                  value={
                                    (voiceRetention
                                      && voiceRetention.communication_history
                                      && voiceRetention.communication_history.value
                                    )
                                      ? voiceRetention.communication_history.value
                                      : null
                                  }
                                  onChange={this.changeValue}
                                />
                              </Form.Group>
                            </Col>

                            {(voiceRetention
                              && voiceRetention.communication_history
                              && voiceRetention.communication_history.value
                              && voiceRetention.communication_history.value.value
                            )
                              ? (
                                <Col md={5}>
                                  <Form.Group controlId="retention-duration">
                                    <Form.Label>DELETE FILES AFTER:</Form.Label>
                                    <input
                                      type="number"
                                      className="select-container days-count-mretention"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.communication_history
                                          && voiceRetention.communication_history.duration
                                          && voiceRetention.communication_history.duration.count
                                        )
                                          ? voiceRetention.communication_history.duration.count
                                          : ''
                                      }
                                      onChange={this.onInputChange}
                                      name="communication_history-count"
                                      min="1"
                                      max="999"
                                      required
                                    />

                                    <Select
                                      options={voiceRetentiontDuration}
                                      className="select-container days-type"
                                      value={
                                        (voiceRetention
                                          && voiceRetention.communication_history
                                          && voiceRetention.communication_history.duration
                                          && voiceRetention.communication_history.duration.period
                                        )
                                          ? voiceRetention.communication_history.duration.period
                                          : null
                                      }
                                      onChange={this.changePeriod}
                                      name="communication_history-period"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null }
                          </Row>

                          <div className="line-sep" />

                          <Row>
                            {/* <Col md={12} >
                                  <div className='retention-message'>
                                  <Form.Group>
                                      <div className="custom-control custom-checkbox mb-1">
                                          <input type="checkbox" className="custom-control-input"
                                          id="workspaceoverride" required />
                                          <label className="custom-control-label" htmlFor="workspaceoverride">
                                            Let workspace members override these settings:
                                          </label>

                                      </div>

                                      <div id="workspaceoverride-message">
                                          This setting allows members to set their own retention period -
                                          making it shorter or longer - for private channels and direct messages.
                                          Owners will be able to adjust this for public channels, too. Learn more
                                      </div>
                                  </Form.Group>

                                  </div>
                              </Col> */}
                            <Col md={12}>
                              <Button type="submit" variant="blue">SAVE CHANGES</Button>
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </DashBoard>
      </Layout>
    );
  }
}

VoiceRetention.propTypes = {
  fetchVoiceRetention: PropTypes.func.isRequired,
  postVoiceRetention: PropTypes.func.isRequired,
  voiceRetentionData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  uiVoiceRetention: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

VoiceRetention.defaultProps = {
  voiceRetentionData: {},
};

export default VoiceRetention;
